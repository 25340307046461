import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import FeedItem from "../feedItem";
import "./feeds.css";
import AddPostModal from "../addPostModal";
import LoginManager from "../loginManager";
import Loader from "react-loader-spinner";

const styles = {};

@inject(stores => ({
  userStore: stores.userStore,
  feedStore: stores.feedStore
}))
@observer
export default class Feeds extends Component {
  constructor() {
    super();

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      showEdit: false,
      width: null,
      height: null,
      fadeIndex: 0,
      activeKey: 1,
      showModal: false,
      selectedPost: null
    };
  }

  componentDidMount() {
    this.props.feedStore.fetchPosts();
    this.props.userStore.fetchUserInfo();
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const fetchState = this.props.feedStore.state;
    const loggedIn = this.props.userStore.loggedIn;

    if (fetchState === "pending") {
      return <Loader type="Oval" color="#123123" height={80} width={80} />;
    } else if (fetchState === "done") {
      return (
        <div>
          <p>
            Bleiben Sie immer auf dem Laufenden über neue Kollektionen, Events
            und Besonderheiten.
            <br />
            Wir informieren Sie gerne.
          </p>
          {this.props.feedStore.posts.reverse()
            .map((post, i) => {
              return (
                <FeedItem
                  index={i + 1}
                  id={post.id}
                  header={post.header}
                  text={post.text}
                  time={post.time}
                  key={post + i}
                />
              );
            })
            }
          {loggedIn ? (
            <div>
              <AddPostModal />
            </div>
          ) : null}
          <LoginManager />
        </div>
      );
    } else if (fetchState === "error") {
      return <div>error: {this.props.feedStore.errorMessage}</div>;
    } else {
      return <div>...</div>;
    }
  }
}

import React, {Component} from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'mobx-react';

import Feeds from './components/feeds';


import feedStore from './stores/feedStore';
import userStore from './stores/userStore';

const stores = {
    feedStore
    , userStore
};

export default class App extends Component {
    render() {
        return (

            <Provider {...stores}>
                <BrowserRouter>
                    <Feeds/>
                </BrowserRouter>
            </Provider>
        );
    }
}
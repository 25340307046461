import React, {Component} from "react";
import {inject, observer} from 'mobx-react';
import userStore from "../../stores/userStore";
import LoginModal from "../loginModal";
import "../loginModal/loginModal.css";

@inject((stores) => ({
    userStore: stores.userStore,
    feedStore: stores.feedStore
}))
@observer
export default class LoginManager extends Component {
    render() {
        if (this.props.userStore.loggedIn) {
            return (
                <div>
                    <hr/>
                    <a className="pointer" onClick={() => userStore.logout()}>
                        Abmelden
                    </a>
                </div>)
        }
        else {
            return <LoginModal/>
        }
    }
}
import {observable, action} from 'mobx';
import * as api from '../utils/api/api';

class FeedStore {
    @observable posts = [];
    @observable state = null; // "pending", "done", "error"

    @observable errorMessage = "";

    @action fetchPosts() {
        this.state = "pending";

        api.fetchPosts()
            .then(resp => {
                if (resp && resp.message) {
                    this.errorMessage = resp.message;
                }
                else {
                    this.errorMessage = "";
                }

                setTimeout(() => {
                    this.posts = resp.posts;
                    this.state = "done";
                }, 1000)
            });
    }

    @action addFeed(text, header) {
        api.createPost({text, header})
            .then(resp => {
                if(resp.message)
                {
                    this.errorMessage = resp.message;
                }
                else {
                    this.posts = resp.posts;
                }
            });
    }

    @action deleteFeed(id) {
        api.deletePost(id)
            .then(resp => {
                if (resp.message) {
                    this.errorMessage = resp.message;
                }
                else {
                    this.errorMessage = "";
                    this.posts = resp.posts;
                }

            });
    }
}

export default new FeedStore();
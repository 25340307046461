import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {
    Button, Modal, Glyphicon, FormGroup,
    FormControl, Col, Form, Alert, ControlLabel, Popover
} from 'react-bootstrap';
import "./loginModal.css";

@inject((stores) => ({
    userStore: stores.userStore,
    feedStore: stores.feedStore,
}))
@observer
export default class LoginModal extends Component {
    constructor() {
        super();

        this.handleHide = this.handleHide.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.keyPressed = this.keyPressed.bind(this);

        this.state = {
            show: false,
            username: null,
            password: null
        };
    }

    handleHide() {
        this.setState({show: false});
    }

    handleChange = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    keyPressed = (target) => {
        if (target.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        this.props.userStore.logIn({
            username: this.state.username,
            password: this.state.password
        });

        this.setState({username: null, password: null, show: false});
    };

    render() {
        return (
            <div className="modal-container">
                <a className="pointer" onClick={() => this.setState({show: true})}>
                    <Glyphicon glyph="pencil"/>
                </a>
                {
                    (this.props.userStore.errorMessage &&
                    this.props.userStore.errorMessage !== "not authenticated") ?
                        <Alert variant='danger'>
                            <strong>Etwas ist schiefgelaufen!</strong>
                        </Alert>
                        : null
                }
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    onKeyPress={this.keyPressed}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            Login
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Form horizontal>
                            <FormGroup controlId="formHorizontalEmail">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Nutzername
                                </Col>
                                <Col sm={10}>
                                    <FormControl type="username" placeholder="Nutzername"
                                                 onChange={(event) => this.setState({username: event.target.value})}/>
                                </Col>
                            </FormGroup>

                            <FormGroup controlId="formHorizontalPassword">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Passwort
                                </Col>
                                <Col sm={10}>
                                    <FormControl type="password" placeholder="Passwort" autocomplete="new-password"
                                                 onChange={(event) => this.setState({password: event.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col smOffset={2} sm={10}>
                                    <Button onClick={this.handleSubmit}>Anmelden</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                        <Button onClick={this.handleHide} variant="secondary">Schließen</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
import {observable, action} from 'mobx';
import * as api from '../utils/api/api';

class UserStore {
    @observable user = null;
    @observable state = "null"; // "done", "fetching", "error"

    @observable loggedIn = false;

    @observable errorMessage = null;

    @action logIn({username, password}) {
        api.login({username, password})
            .then(resp => {

                this.state = "fetching";

                if (resp && resp.user) {
                    this.user = resp;
                    this.state = "done";
                    this.loggedIn = true;
                    this.errorMessage = null;
                }
                else if(resp && resp.error)
                {
                    this.errorMessage = resp.error;
                }
                else {
                    this.state = "done";
                    this.loggedIn = false;
                    this.user = null;
                    this.errorMessage = null;
                }
            });
    }

    @action logout() {
        api.logout()
            .then(resp => {
                this.user = null;
                this.loggedIn = false;
            });
    }

    @action fetchUserInfo() {
        this.state = "fetching";

        api.getUserInfo()
            .then(resp => {
                this.state = "fetching";

                if (resp.message) {
                    this.state = "error";
                    this.errorMessage = resp.message;
                    this.loggedIn = false;
                    this.user = null;
                }
                else {
                    this.user = resp;
                    this.state = "done";
                    this.loggedIn = true;
                }
            })
            .catch(err => console.log(err));
    }
}

export default new UserStore();


import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ControlLabel, Modal, Button, Glyphicon, Form, FormGroup, Col, FormControl} from 'react-bootstrap';

@inject((stores) => ({
    userStore: stores.userStore,
    feedStore: stores.feedStore
}))
@observer
export default class AddPostModal extends Component {
    constructor(props) {
        super(props);


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.keyPressed = this.keyPressed.bind(this);

        this.state = {
            text: null,
            header: null,
            show: false
        }
    }

    handleHide() {
        this.setState({ show: false });
    }

    handleSubmit = () => {
        this.props.feedStore.addFeed(
            this.state.text,
            this.state.header
        );

        this.setState({text: null, header: null, show:false});
    };

    keyPressed = (target) => {
        if(target.key === "Enter") {
            this.handleSubmit();
        }
    };

    render(){
        return(
            <div className="modal-container">
                <Button
                    bsStyle="primary"
                    bsSize="large"
                    onClick={() => this.setState({ show: true })}
                >
                    Hinzufügen <Glyphicon glyph="plus"/>
                </Button>

                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    onKeyPress={this.keyPressed}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            News hinzufügen
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Form horizontal>
                            <FormGroup controlId="formControlsText">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Überschrift
                                </Col>
                                <Col sm={10}>
                                    <FormControl type="header" placeholder="Überschrift"
                                                 onChange={(event) => this.setState({header: event.target.value })}/>
                                </Col>
                            </FormGroup>

                            <FormGroup controlId="formControlsText">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Text
                                </Col>
                                <Col sm={10}>
                                    <FormControl type="text" placeholder="Text"
                                                 onChange={(event) => this.setState({text: event.target.value })}/>
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col smOffset={2} sm={10}>
                                    <Button onClick={this.handleSubmit}>Hinzufügen</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                        <Button onClick={this.handleHide}>Schließen</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
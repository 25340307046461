// const BASE_URL = "/api/";

// const getUrl = link => `${BASE_URL}${link}`;
const requestInit = {credentials: 'include'};

const processResponse = resp =>
    resp.json().then(data => {
        if (data.error) {
            return Promise.reject(data);
        }
        // empty object means that there were no data found
        if (!Array.isArray(data) && !Object.keys(data).length) {
            return Promise.reject({
                error: "No data",
                data
            });
        }
        return data;
    });

export const logout = () =>
    fetch('/logout', requestInit);

export const login = ({username, password}) => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return fetch('/login',
        {credentials: 'include', method: 'POST', body: params})
        .then(processResponse)
        .catch(err => {
            return {error: err.message}
        });
};

export const getUserInfo = () =>
    fetch('/user', requestInit)
        .then(processResponse)
        .catch(err => console.log(err));


export const fetchPosts = () =>
    fetch('/posts', requestInit)
        .then(processResponse)
        .catch(err => console.log(err));

export const createPost = ({header, text}) => {
    const params = new URLSearchParams();
    params.append('header', header);
    params.append('text', text);

    return fetch('/posts',
        {
            method: 'POST',
            body: params,
            credentials: 'include'
        })
        .then(processResponse)
        .catch(err => console.log(err));
};

export const deletePost = (id) => {
    const params = new URLSearchParams();
    params.append('id', id);
    return fetch('/posts',
        {
            method: 'DELETE',
            body: params,
            credentials: 'include'
        })
        .then(processResponse)
        .catch(err => console.log(err));
};



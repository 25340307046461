import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Button, Modal} from 'react-bootstrap';
import "./feedItem.css";

const styles = {
    root: {
        fontFamily: "'Raleway', sans-serif",
        lineBreak: "auto"
    },
    line: {
        height: "2px",
        width: "70px",
        textAlign: "center",
        position: "relative",
        background: "#1E7A46",
        marginBottom: "20px",
        border: "0"
    }
};

@inject((stores) => ({
    userStore: stores.userStore,
    feedStore: stores.feedStore,
}))
@observer
export default class FeedItem extends Component {

    constructor() {
        super();

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false
        };

        this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem = () => {
        this.setState({ show: false });
        this.props.feedStore.deleteFeed(this.props.id);
    };

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        let loggedIn = this.props.userStore.user;
        const {header, text, time} = this.props;

        return (
            <div style={styles.root} className="col-md-12">
                <h2 className="headingH1">{header}</h2>
                <p className="newsText">{text}</p>
                <span className="badge">gepostet am {time}</span>
                <div className="pull-right">
                    <span className="label label-primary">Info</span>
                </div>
                {

                    loggedIn ?
                        <div>
                        <Button className="deleteBtn" bsStyle="danger"
                                bsSize="xsmall" onClick={this.handleShow}>
                            Löschen
                        </Button>
                            <Modal show={this.state.show} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Soll der Post wirklich gelöscht werden?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h2 className="headingH1">{header}</h2>
                                    <p className="newsText">{text}</p>
                                    <span className="badge">gepostet am {time}</span>
                                    <div className="pull-right">
                                        <span className="label label-primary">Info</span>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.handleClose}>Schließen</Button>
                                    <Button onClick={this.deleteItem}>Löschen</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        : null
                }
                <hr style={styles.line}/>
            </div>
        )
    };
}